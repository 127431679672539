import ALL_CATEGORIES from '~/graphql/categories/query/getAllCategories.gql'
import { useI18n } from 'vue-i18n'

const categories = ref<{label: string; value: string}[]>([])
const key = ref(0)
const firstTime = ref(true)

export function categoriesAutocomplete(params: any) {
  const apolloQuery = ALL_CATEGORIES
  const variables = {
    orderBy: [
      {
        column: 'NAME',
        order: 'ASC'
      }
    ],
    page: 1,
    first: 10,
    search: '%' + params.search + '%'
  }

  const result = query(apolloQuery, variables, { fetchPolicy: 'network-only' })

  return result
}

export async function searchCategoriesCb(queryString: any, cb: (arg0: any) => void) {
  const { $showError, $sentry } = useNuxtApp()
  try {
    const results = await categoriesAutocomplete({ search: queryString })
    cb(results?.getAllCategories?.data ?? [])
  } catch (err) {
    $showError(err)
    $sentry(err, 'searchCategoriesCb')
  }
}

export function getSearchCategories(entityCategory: any, entityCategories: any, t: any) {
  if (firstTime.value === true) {
    firstTime.value = false
    searchCategories('', entityCategory, entityCategories)
  }

  return categories.value.map((i: any) => {
    return {
      label: t(i.label.toLowerCase()),
      value: i.value
    }
  })
}

export async function searchCategories(queryString: any, entityCategory: any, entityCategories: any[], refresh = false) {
  const { $showError, $sentry } = useNuxtApp()
  try {
    const results = await categoriesAutocomplete({ search: queryString })
    const remoteCategories = results?.getAllCategories?.data?.map((i: any) => {
      return {
        label: i.name,
        value: i.uuid
      }
    }) ?? []
    const categoriesToAdd: { label: any; value: any }[] = []
    entityCategories?.forEach((j: any) => {
      if (!remoteCategories.find((k: any) => k.value === j.uuid)) {
        categoriesToAdd.push({
          label: j.name,
          value: j.uuid
        })
      }
    })
    if (entityCategory && !remoteCategories.find((k: any) => k.value === entityCategory.uuid)) {
      categoriesToAdd.push({
        label: entityCategory.name,
        value: entityCategory.uuid
      })
    }
    if (refresh) {
      key.value = 0
    }
    if (key.value === 0) {
      key.value = setCustomKeyVariable()
    }
    categories.value = [...remoteCategories, ...categoriesToAdd]
    firstTime.value = false

    if (entityCategory) {
      getSearchCategories(entityCategory, entityCategories, entityCategory)
    }
  } catch (err) {
    $showError(err)
    $sentry(err, 'searchCategories')
  }
}

export function getCategoryKey() {
  return key.value
}

export function initialCategories () {
  categories.value = []
  key.value = 0
  firstTime.value = true
}